import * as React from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import {
  ActiveLanguageToLocale,
  Jurisdiction,
  Jurisdictions,
  Option,
} from '@dnc/baseline';
import { LocaleContext } from '@dnc/shared-components';

import EnLogo from '../../public/i-will-vote-logo-we.svg';
import EsLogo from '../../public/voy-a-votar-logo.svg';
import { UrlHelper } from '../../services/url-helper';

type HeaderProps = {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  locateLayout?: boolean;
};

export const HOTLINE_SMS_BODY_MSG = defineMessage({
  defaultMessage: 'VOTE',
  id: 'mQzbfB',
  description:
    'The body of the SMS sent to 70888 to get a response. Varies by language so we can respond in the right language.',
});

export const Header: React.FC<HeaderProps> = ({
  jurisdiction,
  customVoterHotline,
  locateLayout,
}) => {
  const intl = useIntl();
  const locale = React.useContext(LocaleContext);
  const location = useLocation();
  const Logo = locale === ActiveLanguageToLocale['Spanish'] ? EsLogo : EnLogo;

  const urlHelper = UrlHelper.fromLocation(location);

  const logoAriaLabel = intl.formatMessage({
    id: 'wrapper.appName',
    defaultMessage: 'I Will Vote',
  });

  const hotlineSmsBodyText = intl.formatMessage(HOTLINE_SMS_BODY_MSG);

  const currentJurisdictionName = Jurisdictions.isJurisdiction(jurisdiction)
    ? Jurisdictions.localized(jurisdiction, intl)
    : undefined;
  if (locateLayout) {
    return (
      <header className="header">
        <div className="header__inner">
          <Link
            role="banner"
            to={urlHelper.homeURL(jurisdiction)}
            aria-label="IWillVote.com"
            tabIndex={0}
          >
            <Logo aria-label={logoAriaLabel} className="logo header__logo" />
          </Link>
          <div className="header__content">
            {jurisdiction ? (
              <div className="header__current-state">
                {currentJurisdictionName}
              </div>
            ) : null}
          </div>
        </div>
      </header>
    );
  }
  return (
    <div className="flex flex-col gap-2 pb-1 lg:gap-3">
      <Link
        role="banner"
        to={urlHelper.homeURL(jurisdiction)}
        aria-label="IWillVote.com"
        tabIndex={0}
      >
        <Logo aria-label={logoAriaLabel} className="logo" />
      </Link>

      <div className="text-lilac-800 text-sm my-4 lg:text-base">
        <FormattedMessage
          defaultMessage="Have questions about voting?"
          id="wrapper.haveQuestionsHtml.questionsAboutVoting"
          tagName="strong"
        />
        <br />
        {jurisdiction && customVoterHotline ? (
          <FormattedMessage
            defaultMessage="Call the {jurisdiction} voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
            values={{
              smsBody: hotlineSmsBodyText,
              'sms-link': (chunks) => (
                <a
                  href={`sms://70888&body=${hotlineSmsBodyText}`}
                  className="no-wrap"
                >
                  {chunks}
                </a>
              ),
              hotline: (
                <a href={`tel:${customVoterHotline}`} className="no-wrap">
                  {customVoterHotline}
                </a>
              ),
              jurisdiction: currentJurisdictionName,
            }}
            id="OhpnmQ"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Call our voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
            values={{
              smsBody: hotlineSmsBodyText,
              'sms-link': (chunks) => (
                <a
                  href={`sms://70888&body=${hotlineSmsBodyText}`}
                  className="no-wrap"
                >
                  {chunks}
                </a>
              ),
              hotline: (
                <a href="tel:1-833-336-8683" className="no-wrap">
                  833-336-8683
                </a>
              ),
            }}
            id="HllheH"
          />
        )}
        <br />
        <a href="#sms-disclaimer">
          <FormattedMessage
            defaultMessage="Disclaimer below."
            id="wrapper.haveQuestionsHtml.disclaimer"
          />
        </a>
      </div>
    </div>
  );
};
